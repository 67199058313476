<template>
  <div class="users">
    <Header title="数据中心" index="首页" beforeTitle="数据中心" />
    <div class="content">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="公用数据" name="first">
          <Alldata />
        </el-tab-pane>
        <el-tab-pane label="我的数据" name="second">
          <Mydata />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Alldata from "../../components/DataContent/all.vue";
import Mydata from "../../components/DataContent/personal.vue";
export default {
  inject: ["reload"],
  data() {
    return {
      activeName: "first",
      mydatalist: [],
    };
  },
  methods: {
    handleClick() {
      if (this.activeName == "first") {
        this.$store.dispatch("getConList", {
          page: 1,
          limit: 10,
          keywords: "",
          order: "id desc",
          type: "all",
          status: "",
        });
      } else if (this.activeName == "second") {
        this.$store.dispatch("getMydataList", {
          page: 1,
          limit: 10,
          keywords: "",
          order: "id desc",
          type: "personal",
          status: "",
        });
      }
    },
    deleteRow(id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/data/delete", {
            params: {
              id: id,
            },
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });

          this.$store.dispatch("getConList", this.conlistParams);
          this.reload();
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSearch() {
      this.$store.dispatch("getConList", this.conlistParams);
    },
  },
  beforeUpdate() {
    this.loading = false;
  },
  components: {
    Alldata,
    Mydata,
  },
};
</script>

<style>
.users {
  height: 100%;
}
.topUsers {
  margin-top: 22px;
}
</style>
