<template>
  <!-- 公开数据页面 -->
  <div class="alldata">
    <div class="topUsers">
      <div>
        <el-input
          clearable
          v-model="conlistParams.keywords"
          placeholder="请输入名称关键字"
          @keyup.enter.native="handleSearch()"
        ></el-input>

        <el-input
          clearable
          v-model="conlistParams.true_name"
          placeholder="请输入撰写人关键字"
          @keyup.enter.native="handleSearch()"
        ></el-input>

        <el-date-picker
          clearable
          v-model="times"
          type="daterange"
          align="right"
          unlink-panels
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          style="margin-right: 24px;"
        ></el-date-picker>

        <el-button class="search" @click="handleSearch()">查询</el-button>
        <el-button
          class="export"
          :loading="exportLoading"
          @click="dataExport = dialogVisible = true"
        >{{ exportText }}</el-button>
      </div>
    </div>

    <el-table
      v-loading="loading"
      element-loading-text="正在加载中···"
      :data="conlist.data"
      style="width: 100%"
      v-if="privilige_list.public_data.includes('list')"
    >
      <el-table-column prop="title" label="名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="true_name" label="撰写人" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="update_time" label="更新时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ scope.row.update_time | fmtdate }}</template>
      </el-table-column>
      <el-table-column prop="operation" label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="privilige_list.public_data.includes('view')"
            class="edit"
            size="mini"
            @click="checkconlist(scope.row.id)"
          >查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block" v-if="conlist.total > 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="conlistParams.page"
        :page-sizes="[2, 10, 20, 40]"
        :page-size="conlistParams.limit"
        layout="sizes, prev, pager, next,total,jumper"
        :total="conlist.total"
      ></el-pagination>
    </div>
    <el-dialog title="选择导出日期" :visible.sync="dialogVisible" width="30%">
      <div class="img_box">
        <el-date-picker
          v-model="dataTime"
          type="daterange"
          align="right"
          unlink-panels
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dataSure()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import user from '../../components/global.vue'
import { mapState } from "vuex";
export default {
  data () {
    return {
      times: '',//筛选日期区间
      exportText: '导出',
      exportLoading: false,
      expotrUrl: user.userSite,
      dialogVisible: false,
      dataTime: '',
      loading: true,
      conlistParams: {
        page: 1,
        limit: 10,
        keywords: "",
        order: "id desc",
        type: "all",
        status: "",
        start_time: '',
        end_time: '',
      },
      privilige_list: {},
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    };
  },
  computed: {
    ...mapState(["conlist"]),
  },
  created () {
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
    this.$store.dispatch("getConList", this.conlistParams);
  },
  methods: {
    dataSure () {
      if (this.dataTime) {
        this.axios.get('/api/data/export_share_data', {
          params: {
            start_time: this.dataTime[0],
            end_time: this.dataTime[1]
          }
        }).then((a) => {
          window.open(this.expotrUrl + a.data.url)
          this.exportLoading = true
          this.exportText = '导出中'
          this.dialogVisible = false
          // 判断文档和所有子资源(图片、音视频等)已完成加载
          if (document.readyState === 'complete') {
            this.exportLoading = false
            this.exportText = '导出'
          }
          console.log(document.readyState, '下载状态')
          // window.onload = function () {
          // }
        })
      } else {
        this.$message.warning('请先选择日期区间')
      }
    },
    handleSearch () {
      if (this.times) {
        this.conlistParams.start_time = this.times[0]
        this.conlistParams.end_time = this.times[1]
        this.$store.dispatch("getConList", { ...this.conlistParams, page: '', limit: '' });
      } else {
        this.$store.dispatch("getConList", { ...this.conlistParams, page: '', limit: '' });
      }
    },
    checkconlist (id) {
      this.$router.push({ path: "/data/check", query: { id: id } });
    },
    handleSizeChange (val) {
      this.conlistParams.limit = val;
      this.$store.dispatch("getConList", { ...this.conlistParams });
    },
    handleCurrentChange (val) {
      this.conlistParams.page = val;
      this.$store.dispatch("getConList", { ...this.conlistParams });
    },
  },
  beforeUpdate () {
    this.loading = false;
  },
};
</script>

<style scoped>
div/deep/.el-table__row {
  height: 56px;
}
.img_box {
  margin: 30px 0;
  display: flex;
  justify-content: space-around;
}
.dialog-footer {
  display: flex;
  justify-content: space-around;
}
div/deep/.el-dialog__title {
  font-weight: bold;
}
.export {
  border: #edeef4;
  background: #edeef4;
  color: #909399;
}
</style>
