<template>
  <!-- 个人数据页面 -->
  <div class="mydata">
    <div class="topUsers">
      <div style="display:flex">
        <el-input
          v-model="mydataParams.keywords"
          placeholder="请输入名称关键字"
          @keyup.enter.native="handleSearch()"
        ></el-input>
        <el-form :model="mydataParams" label-width="100px" class="demo-ruleForm">
          <el-form-item>
            <el-select
              @change="searchStatus"
              clearable
              v-model="mydataParams.status"
              placeholder="数据类型"
            >
              <el-option label="公用数据" value="1"></el-option>
              <el-option label="我的数据" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-button class="search" @click="handleSearch()">查询</el-button>
      </div>
      <el-button
        v-if="privilige_list.self_data.includes('add')"
        class="add"
        @click="addconlist()"
      >新增</el-button>
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="正在加载中···"
      :data="mydatalist.data"
      style="width: 100%"
      v-if="privilige_list.self_data.includes('list')"
    >
      <el-table-column prop="title" label="名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="true_name" label="撰写人" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="update_time" label="更新时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ scope.row.update_time | fmtdate }}</template>
      </el-table-column>

      <el-table-column prop="status" label="数据类型" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div v-if="scope.row.status == 1">公用数据</div>
          <div v-if="scope.row.status == 0">我的数据</div>
        </template>
      </el-table-column>
      <el-table-column prop="operation" label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="privilige_list.self_data.includes('edit')"
            class="edit"
            size="mini"
            @click="datainfo(scope.row)"
          >编辑</el-button>
          <el-button
            v-if="privilige_list.self_data.includes('delete')"
            class="delet del"
            size="mini"
            @click="datadel(scope.row.id)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block" v-if="mydatalist.total > 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="mydataParams.page"
        :page-sizes="[2, 10, 20, 40]"
        :page-size="mydataParams.limit"
        layout="sizes, prev, pager, next,total,jumper"
        :total="mydatalist.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  inject: ["reload"],

  data () {
    return {
      loading: true,
      typelist: [
        {
          status: "1",
          value: "公用数据",
        },
        {
          status: "0",
          value: "我的数据",
        },
      ],
      mydataParams: {
        page: 1,
        limit: 10,
        keywords: "",
        order: "id desc",
        type: "personal",
        status: "",
      },
      mydata: [],
      privilige_list: {},
    };
  },
  computed: {
    ...mapState(["mydatalist"]),
  },
  created () {
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
    this.$store.dispatch("getMydataList", this.mydataParams);
  },
  methods: {
    searchStatus () {
      this.$store.dispatch("getMydataList", { ...this.mydataParams, page: '', limit: '' });
    },
    handleSearch () {
      this.$store.dispatch("getMydataList", { ...this.mydataParams, page: '', limit: '' });
    },
    datatype (row) {
      return this.typelist.find((item) => item.status == row.status).value;
    },
    handleSizeChange (val) {
      this.mydataParams.limit = val;
      this.$store.dispatch("getMydataList", this.mydataParams);
    },
    handleCurrentChange (val) {
      this.mydataParams.page = val;
      this.$store.dispatch("getMydataList", { ...this.mydataParams });
    },
    addconlist () {
      this.$router.push({ path: "/data/edit" });
    },
    datainfo (row) {
      this.$router.push({ path: "/data/edit", query: { data: row } });
    },
    datadel (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios
            .delete("/api/data/delete", {
              params: {
                id: id,
              },
            })
            .then(() => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.$parent.activeName = "second";
              this.$store.dispatch("getMydataList", this.mydataParams);
            });
          // this.reload();
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  beforeUpdate () {
    this.loading = false;
  },
};
</script>

<style scoped>
.topUsers {
  display: flex;
  align-items: center;
  position: relative;
}
div/deep/.el-form-item {
  margin: 0;
}
div/deep/.el-form-item__content {
  margin-left: 0 !important;
}
.add {
  position: absolute;
  right: 0;
  top: 0;
}
.delet {
  font-size: 12px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #ff0f0f;
  border: none;
  background: #f9f2f2;
}
</style>
